import React from "react";
import comtal from '../Images/comtal-logo.jpg';
import kubera from '../Images/kubera-logo.jpeg';
import { Card, Row, Col } from "react-bootstrap";
import { Styles } from '../Styles/QbatzStyles';
import { FaStar } from "react-icons/fa"; 
import './PeopleSays.css';

function PeopleSays() {
    const testimonials = [
        {
            id: 1,
            logo: kubera,
            name: 'Krishnakumar Sriramulu',
            company: 'Kubera Financial',
            rating: 5,
            feedback: 'As a client, I am very happy that my website was developed on time and with the exact ask. My business is running now. Vijay and Immanuvel, who worked for my website, are very talented. I endorse this team for any endeavor. Congrats Immanuvel and team.',
        },
        {
            id: 2,
            logo: comtal,
            name: 'Elango',
            company: 'Comtal Technologies',
            rating: 5,
            feedback: 'We wanted to take a moment to sincerely thank you for your hard work and dedication in creating the application for our laptop rental business. Your expertise and attention to detail have made a significant impact on the functionality and user experience of the platform.',
        },
    ];

    return (
        <div className="container mb-5">
            <div className='people_title d-flex justify-content-left mb-3 '>
                <label className='people' style={{
                    fontWeight: Styles.FontSemibold,
                    // fontSize: window.innerWidth < 768 ? "40px" : Styles.Font44,
                    fontFamily: Styles.fontFamilyMontserrat
                }}>What people say about us<span style={{ color: Styles.colorBlue }}>.</span></label>
            </div>

            <Row>
                {testimonials.map((testimonial) => (
                    <Col md={6} key={testimonial.id} className="mb-4">
                        <Card className="p-3 shadow-sm" style={{ marginTop: '40px' }}>
                            <div className="d-flex align-items-start">
                                <img
                                    src={testimonial.logo}
                                    alt={`${testimonial.name} logo`}
                                    style={{ width: "60px", borderRadius: "50%" }}
                                />
                                <div className="ml-3" style={{ textAlign: "start", paddingLeft: "30px" }}>
                                    <h5 className="card-title mb-0"
                                        style={{
                                            fontFamily: Styles.fontFamilyMontserrat,
                                            fontSize: Styles.Font20,
                                            fontWeight: Styles.FontBold
                                        }}>{testimonial.name}</h5>
                                    <h6 className="card-subtitle"
                                        style={{
                                            fontFamily: Styles.fontFamilyMontserrat,
                                            fontSize: Styles.Font15,
                                            fontWeight: Styles.FontMedium,
                                            paddingTop: "6px",
                                            color: "#888888",
                                        }}>{testimonial.company}</h6>
                                    {/* Star Rating */}
                                    <div style={{ marginTop: "5px", color: "#FFD700" }}>
                                        {Array.from({ length: testimonial.rating }, (_, index) => (
                                            <FaStar key={index} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <Card.Body>
                                <p className="card-text" style={{
                                    textAlign: "start",
                                    fontFamily: Styles.fontFamilyRoboto,
                                    fontWeight: Styles.FontMedium,
                                    fontSize: Styles.Font14,
                                    color: "#141414",
                                    lineHeight: "25px"
                                }}>{testimonial.feedback}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default PeopleSays;
