import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import pic from '../Images/courses.svg';
import { Styles } from '../Styles/QbatzStyles';
import './Courses.css'

const Courses = () => {
  useEffect(() => {
    const appearOptions = {
      threshold: 0.5
    };

    const faders = document.querySelectorAll('.fade-in');
    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return;
        entry.target.classList.add('appear');
        observer.unobserve(entry.target);
      });
    }, appearOptions);

    faders.forEach(fader => {
      appearOnScroll.observe(fader);
    });
  }, []);

  return (
    <div className="container" style={{ paddingTop: "40px", paddingBottom: "60px" }}>
      <p className="course-heading text-start fade-in" style={{
        // fontSize:"50px",
        color: "#141414",
        fontFamily: Styles.fontFamilyMontserrat,
        fontWeight: Styles.FontSemibold,
      }}>
        We provide courses that upskill you<span style={{ color: '#0070f3' }}>.</span>
      </p>
      <div className="row" style={{ paddingTop: "10px" }}>
        <div className="col-lg-5 col-md-12 col-sm-12 course-image fade-in">
          <img src={pic} alt="pic" className="" style={{ width: "100%" }} />
        </div>
        <div className='col-lg-1'></div>
        <div className="course-Subheading col-lg-6 col-md-12 col-sm-12 course-text fade-in">
          <h2 style={{
            // fontSize: Styles.Font30,
            color: "#141414",
            fontFamily: Styles.fontFamilyMontserrat,
            fontWeight: Styles.FontExtraBold,
            paddingTop: "20px"
          }}>
            Specialize in high-demand <br /> technical courses
          </h2>
          <p style={{
            fontSize: Styles.Font15,
            color: "#141414",
            fontFamily: Styles.fontFamilyRoboto,
            fontWeight: Styles.FontMedium,
            paddingTop: "10px"
          }}>
            A well-structured curriculum tailored to industry demands ensures that learners acquire relevant skills. It focuses on practical knowledge and up-to-date content, preparing students for real-world challenges.
          </p>
          <button
            style={{
              backgroundColor: Styles.backgroundColorTransparent,
              color: Styles.colorBlue,
              width: Styles.Width161,
              height: Styles.Height61,
              marginTop: '10px',
              fontSize: '18px',
              fontWeight: 600,
              fontFamily: 'Roboto',
              borderRadius: '12px',
              border: '1.4px solid #0047FF',

            }}
          >
            Join Course
          </button>
        </div>
      </div>
    </div>
  );
};

export default Courses;


