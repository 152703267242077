import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';
import { Styles } from '../Styles/QbatzStyles';

function Services() {
    const services = [
        { name: "Custom Software Development", description: "We specialize in developing bespoke software solutions tailored to meet your business needs. Whether you are a start-up, small business, or large enterprise, our team of experienced developers and engineers work closely with you to deliver high-quality, scalable, and secure software that helps streamline your operations and drive growth." },
        { name: "Software Consulting", description: "We offer expert software consulting services to help your business leverage the right technology for success. Whether you’re looking to implement new software, optimize your current systems, or need guidance on a custom software development project, our team of experienced consultants is here to provide strategic insights and practical solutions tailored to your needs." },
        { name: "Ongoing Maintenance and Support", description: "Our commitment to your success doesn’t end when your software goes live. We provide comprehensive ongoing maintenance and support services to ensure your software remains efficient, secure, and up-to-date. Our proactive approach ensures that your systems continue to perform at their best as your business grows and evolves." },
        { name: "HRMS Consulting and Streamlining", description: "We offer expert HRMS (Human Resource Management System) consulting and streamlining services to help businesses optimize their human resources operations through technology. Our team of consultants specializes in analyzing, selecting, and implementing HRMS solutions that simplify HR processes, improve efficiency, and enhance the overall employee experience." },
        { name: "Customer Support as a Service", description: "We understand that exceptional customer support is vital for building strong relationships and retaining loyal customers. Our Customer Support as a Service (CSaaS) solution is designed to help businesses offer seamless, round-the-clock support without the need for an in-house team. We provide trained professionals who handle all customer queries, technical issues, and feedback with efficiency and empathy." },
        { name: "Software Developer Outsourcing", description: "We offer expert Software Developer Outsourcing services to help businesses build and scale their development teams effortlessly. Whether you're looking to augment your existing team or need a dedicated group of developers to tackle an entire project, we provide highly skilled professionals ready to deliver results." }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleService = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        const appearOptions = {
            threshold: 0.5
        };

        const faders = document.querySelectorAll('.fade-in'); 
        const appearOnScroll = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) return;
                entry.target.classList.add('appear');
                observer.unobserve(entry.target);
            });
        }, appearOptions);

        faders.forEach(fader => {
            appearOnScroll.observe(fader);
        });
    }, []);

    return (
        <div className='services_All' style={{ background: Styles.backgroundColorGrey }}>
            <div className='container' style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                <div className='row'>
                    <div className='col-lg-1 col-sm-1 col-xs-1 d-flex flex-direction-column justify-content-start'>
                        <div className="vertical-line" style={{ fontFamily: Styles.fontFamilyRoboto }}></div>
                    </div>
                    <div className='col-lg-11 col-sm-11 col-xs-11'>
                        <div style={{ flex: "10" }}>
                            <div
                                className="services-title fade-in"
                                style={{
                                    fontFamily: Styles.fontFamilyMontserrat,
                                    fontWeight: Styles.FontSemibold,
                                    
                                }}
                            >
                                <span className="services-header">Services</span>
                                <span className="tailored-text">
                                    Tailored for you
                                    <span style={{ color: Styles.colorBlue }}>.</span>
                                </span>
                                <span className="tailored-dot" style={{ color: Styles.colorBlue }}>.</span>
                            </div>

                            {services.map((service, index) => (
                                <div key={index} className="fade-in" style={{}}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "10px 0",
                                            borderBottom: "2px solid #D1D1D1",
                                        }}
                                    >
                                        <div className="service-item"
                                            style={{
                                                fontFamily: Styles.fontFamilyMontserrat,
                                                fontWeight: Styles.FontSemibold,
                                                fontSize: Styles.Font16,
                                            }}> 
                                            {service.name}
                                        </div>
                                        <div
                                            onClick={() => toggleService(index)}
                                            style={{
                                                fontFamily: Styles.fontFamilyMontserrat,
                                                fontWeight: Styles.FontSemibold,
                                                fontSize: Styles.Font22,
                                                color: Styles.colorBlack,
                                                cursor: "pointer",
                                            }}
                                        >
                                            {activeIndex === index ? "-" : "+"}
                                        </div>
                                    </div>
                                    <div
                                        className={`service-detail ${activeIndex === index ? 'show' : 'hide'}`}
                                        style={{
                                            fontFamily: "inherit",
                                            backgroundColor: '#fff',
                                            padding: '10px',
                                            transition: 'max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease',
                                            borderBottomLeftRadius: '5px',
                                            borderBottomRightRadius: '5px',
                                            maxHeight: activeIndex === index ? '250px' : '0',
                                            opacity: activeIndex === index ? '1' : '0',
                                            transform: activeIndex === index ? 'translateY(0)' : 'translateY(-20px)',
                                      
                                        }}
                                    >
                                        <p className='description' style={{
                                            fontWeight: Styles.FontMedium,
                                            fontFamily: Styles.fontFamilyRoboto,
                                            fontSize: Styles.Font14,
                                         
                                        }}> 
                                            {service.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
