


import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import design from '../Images/Designing-A-Website-2--Streamline-Brooklyn.svg';
import './Home.css';
import { Styles } from "../Styles/QbatzStyles";
import { useNavigate } from 'react-router-dom';
import Services from "./Services";
import Innovation from './Innovation';
import NeedFooter from "./Need_Footer";
import Courses from "./Courses";
import Footer from './Footer';
import BestChoice from './BestChoice';
import PeopleSays from "./PeopleSays";

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    const appearOptions = {
      threshold: 0.5,
    };

    const faders = document.querySelectorAll('.fade-in');
    const appearOnScroll = new IntersectionObserver((entries, appearOnScroll) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        entry.target.classList.add('appear');
        appearOnScroll.unobserve(entry.target);
      });
    }, appearOptions);

    faders.forEach(fader => {
      appearOnScroll.observe(fader);
    });
  }, []);

  return (
    <div>
      <div id="home" style={{ paddingTop: "90px" }}>
        <div className="container mb-5">
          <div className="Home_pic row g-0 row-gap-4 align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 fade-in text-left">
              <p style={{
                color: '#141414',
                fontFamily: Styles.fontFamilyMontserrat,
                fontWeight: Styles.FontExtraBold,
                // fontSize: Styles.Font30
              }} className="scalable-text text-start">
                Transforming Ideas into Scalable Solutions
                <span style={{
                  color: "#0047FF",
                  fontFamily: Styles.fontFamilyMontserrat,
                  fontSize: Styles.Font30,
                  fontWeight: Styles.FontExtraBold,
                }} className="dot">.</span>
              </p>
              <p style={{
                color: "#141414",
                fontSize: Styles.Font18,
                fontFamily: Styles.fontFamilyRoboto,
                fontWeight: Styles.FontMedium,
                paddingTop: 20,
              }} className="text-start">
                Specializing in mobile, web, and website development for businesses, we also offer HRMS solutions, resource outsourcing, and customer support solutions.
              </p>
              <div className="text-center text-md-start">
                <button style={{
                  fontSize: Styles.Font14,
                  fontFamily: Styles.fontFamilyRoboto,
                  fontWeight: Styles.FontBold,
                  backgroundColor: '#0047FF',
                  borderRadius: 12,
                  padding: '15px 25px',
                  color: "#FFF",
                  border: 'none',
                  height: "auto",
                  minWidth: "150px",
                  marginTop: 20
                }} className="btn-get"
                  onClick={() => navigate('/contact')}
                >
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center fade-in img-right">
              <img src={design} alt="Team" className="design img-fluid" style={{ maxWidth: '90%', borderRadius: Styles.borderRadius10 }} />
            </div>
          </div>
        </div>
      </div>



      <div id="services" style={{ paddingTop: "60px" }}>
        <Services />
      </div>

      <div id="products" style={{ paddingTop: "60px" }}>
        <Courses />
      </div>


      <div>
        <BestChoice />
        <Innovation />

      </div>

      <div id="testimonials" >
        <PeopleSays />
      </div>
      <NeedFooter />
      <Footer />
    </div>
  );
}

export default Home;

