import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SmartStay from '../Images/smartstay-innovation.svg';
import LaptoLease from '../Images/laptolease-innovation.svg';
import './Innovation.css';
import { Styles } from '../Styles/QbatzStyles';

function Innovation() {
  useEffect(() => {
    const appearOptions = {
      threshold: 0.5,
    };

    const elementsToAnimate = document.querySelectorAll('.left-to-right-animation, .right-to-left-animation');
    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return;
        if (entry.target.classList.contains('left-to-right-animation')) {
          entry.target.classList.add('appear-left');
        } else if (entry.target.classList.contains('right-to-left-animation')) {
          entry.target.classList.add('appear-right');
        }
        observer.unobserve(entry.target);
      });
    }, appearOptions);

    elementsToAnimate.forEach(el => {
      appearOnScroll.observe(el);
    });
  }, []);

  return (
    <div className="container ">
      <p
        className="innovation_title d-flex justify-content-left mb-3 fade-in-animation"
        style={{
          color: Styles.colorBlack,
          fontWeight: Styles.FontSemibold,
          fontFamily: Styles.fontFamilyMontserrat,
          paddingTop:"46px"
          // fontSize: window.innerWidth < 768 ? "32px" : Styles.Font24,
          // fontSize: window.innerWidth < 768 ? "32px" : Styles.Font24,
        }}
      >
        Innovations we Deliver<span style={{ color: Styles.colorBlue }}>.</span>
      </p>

      <div className="row align-items-center mb-5" style={{ marginTop: "70px" }}>
        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 mb-4 left-to-right-animation">
          <img src={SmartStay} className="card-img-top" alt="Card One" />
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 card-two-responsive-margin right-to-left-animation">
          <h2 className="stream-text" style={{
            // fontSize: Styles.Font30,
            fontWeight: Styles.FontExtraBold,
            fontFamily: Styles.fontFamilyMontserrat,
            color: "#141414",
          }}>
            Streamlining PG Management<br /> with an All-in-One Solution
          </h2>
          <p className="b2b-text" style={{
            // fontSize: Styles.Font15,
            marginTop: "20px",
            fontWeight: Styles.FontMedium,
            fontFamily: Styles.fontFamilyRoboto,
            color: "#141414",
          }}>
            SmartStay is a B2B SaaS product that allows PGs, hostels, and service homes management where the
            admin can manage their customers, rooms, vendors, and other services.
          </p>
          <button
            style={{
              borderRadius: "12px",
              padding: "20px 32px",
              fontSize: Styles.Font18,
              fontWeight: Styles.FontSemibold,
              fontFamily: Styles.fontFamilyRoboto,
              color: "#0047FF",
              border: "1px solid #0047FF",
              backgroundColor: "white",
            }}
          >
            Know more
          </button>
        </div>
      </div>

      <div className="row align-items-center mb-5">
        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 mb-4 card-four-responsive-margin left-to-right-animation">
          <h2 className="empower-text" style={{
            fontSize: Styles.Font30,
            fontWeight: Styles.FontExtraBold,
            fontFamily: Styles.fontFamilyMontserrat,
            color: "#141414",
          }}>
            Empower Your Rental Business with LaptoLease
          </h2>
          <p className="lap-text" style={{
            fontSize: Styles.Font15,
            marginTop: "20px",
            fontWeight: Styles.FontMedium,
            fontFamily: Styles.fontFamilyRoboto,
            color: "#141414",
          }}>
            LaptoLease is a B2B SaaS product that enables businesses to rent laptops and other related parts
            and manage their customers, inventory, reports, and expenses.
          </p>
          <button
            style={{
              borderRadius: "12px",
              padding: "20px 32px",
              fontSize: Styles.Font18,
              fontWeight: Styles.FontSemibold,
              fontFamily: Styles.fontFamilyRoboto,
              color: Styles.colorBlue,
              border: "1px solid #0047FF",
              backgroundColor: "white",
            }}
          >
            Coming Soon
          </button>
        </div>

        <div className="col-lg-1"></div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4 right-to-left-animation">
          <img src={LaptoLease} className="card-img-top" alt="Card Four" />
        </div>
      </div>
    </div>
  );
}

export default Innovation;
