import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Need_Footer.css'
import { Styles } from "../Styles/QbatzStyles";
import { useNavigate } from 'react-router-dom';

function NeedFooter() {
  const navigate = useNavigate();
  return (
    <>
      <div className="need-footer" style={{ backgroundColor: '#141414', padding: "30px 0px 30px 0px" }}>
        <div className="container">
          <p
            style={{
              color: '#FFF',
              textAlign: 'left',
              fontFamily: Styles.fontFamilyMontserrat,
              fontSize: Styles.Font44,
              fontStyle: 'normal',
              fontWeight: Styles.FontSemibold,

            }}
            className="need"
          >
            Need a Custom Solution?
          </p>
          <p style={{
            color: '#FFF',
            fontFamily: Styles.fontFamilyRoboto,
            fontSize: Styles.Font14,
            fontStyle: 'normal',
            fontWeight: Styles.FontNormal,
            marginTop: 20,
          }} className="need-text">
            We have the confidence and expertise to challenge the status-quo. Let’s<br />collaborate to take your product to new heights.
          </p>
          <div>
            <button style={{
              backgroundColor: '#0047FF',
              borderRadius: 12,
              padding: '15px 25px',
              color: "#FFF",
              fontFamily: Styles.fontFamilyRoboto,
              fontSize: Styles.Font15,
              fontWeight: Styles.FontSemibold,
              border: 'none',
              marginTop: 15,


            }} className="btn-touch"
              onClick={() => navigate('/contact')}>
              Contact
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NeedFooter;